import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import { Link } from "gatsby"

import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"

import aboutUsImg from "../../../assets/images/sm_circle_AboutUs_2.png"
import bptw from "../../../assets/images/Great_Place_To_Work.png"

import headerHero from "../../../assets/images/header_culture_2.png"
import socialMedia from "../../../assets/images/social-media3.png"
import socialMedia2 from "../../../assets/images/social-media4.png"
import CalloutBlock from "../../../components/CalloutBlock"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"

import Speaker from "../../../assets/images/audrey.png"
import People from "../../../assets/images/oie_transparent.png"

import "./index.scss"
import "../../../scss/_carouseloverride.scss"

const pageTitle = "Our Culture | Harmony Biosciences"
const pageDescription =
  "Our culture is inclusive and collaborative. We work with a one-team attitude and keep patients at the heart. Learn why we’re Great Place to Work Certified."
const pageKeywords = "harmony biosciences - 1,300"

const currentVideo = {
  wistiaID: "1in2y73bsg",
}

class ourCulture extends Component {
  constructor() {
    super()
  }

  dreamBigBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "#NarcolepsyDreamBigWalk"
      )
    }
    window.open(
      "https://www.linkedin.com/feed/topic/?keywords=%23NarcolepsyDreamBigWalk",
      "_blank"
    )
  }

  learnMoreBtn = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "Learn More - Culture"
      )
    }
    window.open("https://lnkd.in/d59_6eX", "_blank")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="our-culture">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/about-us/our-culture"
          />
          <Hero bg={headerHero}>
            <h1>Our Culture</h1>
          </Hero>
          <ContentBlock color="orange">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={aboutUsImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  Driven by a shared conviction
                </h2>
                <p>
                  At <strong>Harmony Biosciences</strong>, we share a singular
                  purpose to address unmet medical needs by developing new
                  medicines for people living with rare neurological diseases.
                  We are led by exceptionally qualified and experienced
                  professionals. Our teams are staffed with strategic thinkers
                  at the top of their fields and driven by life science
                  innovation – all of which translates into teamwork, scientific
                  discourse, development of novel treatment options for people
                  who need them most, and seamless commercial execution.
                </p>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock id="quote1" color="green" className="">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={Speaker} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <div>
                  <p class="quote-content quote-undefined">
                    “Our culture is inclusive and collaborative and defined by
                    our core values – keeping patients at the heart of all we
                    do, acting with integrity and ethics, working with a
                    one-team attitude, leading with the science as we identify
                    clinical possibilities, and delivering excellence.”
                  </p>
                  <p class="bold">
                    Audrey Murphy, SPHR, Vice President, Human Resources
                  </p>
                </div>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="blue">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={bptw} alt="Great Place to Work Certification" className="shrink" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <p className="short">
                  In 2024, Harmony Biosciences was certified as a{" "}
                  <strong>
                    Great Place to Work
                    <sup style={{ fontSize: "10px", lineHeight: "9px" }}>®</sup>
                  </strong>{" "}
                  for the <strong>sixth consecutive year</strong>. This accolade
                  is based on ratings by our employees via an anonymous,
                  independent survey and reflects our team’s commitment and
                  passion for bringing hope to people living with rare
                  neurological diseases. Harmony Biosciences also was recognized
                  in 2022 as a <strong>“Best Workplace in BioPharma,”</strong>{" "}
                  and in 2019 as a{" "}
                  <strong>“Best Small & Medium Workplace”</strong> by Great
                  Places to Work
                  <sup style={{ fontSize: "10px", lineHeight: "9px" }}>®</sup>.
                </p>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock color="orange">
            <Row>
              <Col xs={12} md={12}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  An enriching employee experience
                </h2>
                <p>
                  Providing opportunities to support our team members’ personal
                  and professional development is a passion that everyone at
                  Harmony Biosciences shares. Harmony Biosciences has an ongoing
                  commitment to investing in our people in a variety of ways,
                  including health and wellness, and personal and professional
                  development. To learn more about career development and
                  employment benefits at Harmony, visit our{" "}
                  <a href="/about-us/careers">Careers</a> page.
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="gray">
            <Row>
              <Col xs={12} md={12}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "16px",
                    paddingBottom: "25px",
                  }}
                >
                  Enriching the communities we serve
                </h2>
                <p className="pb-0">
                  As part of the life sciences community, not only do we keep
                  patients at the heart of all we do, we also keep people in
                  need at the heart of all we do by committing time and
                  resources to being of service to the communities in which
                  Harmony Biosciences has a significant employee presence by
                  providing support to economic, social, and professional
                  causes. Our philanthropic efforts are focused on three key
                  areas:
                </p>
                <ul className="careers-ul">
                  <li>
                    <span>
                      Growing our communities by supporting job creation,
                      professional training, and economic development efforts
                    </span>
                  </li>
                  <li>
                    <span>
                      Reducing poverty by providing basic human needs such as
                      food and healthcare
                    </span>
                  </li>
                  <li>
                    <span>
                      Supporting STEM education by advancing science,
                      technology, engineering, and mathematics educational
                      programs and initiatives to help build a pipeline of
                      strong future professionals
                    </span>
                  </li>
                </ul>
                <p>
                  Some of the ways our team members across our organization have
                  made a difference in our communities include participating in
                  service activities by partnering with the{" "}
                  <strong>Boys & Girls Club of Philadelphia</strong> to build
                  bicycles for local children; partnering with{" "}
                  <strong>Life Science Cares Philadelphia</strong> to assemble
                  science experiment kits for youngsters interested in science;
                  holding virtual food drives through community partners such as{" "}
                  <strong>Philabundance</strong> and the{" "}
                  <strong>Chicago Food Repository</strong>; and collaborating
                  with <strong>Cradles to Crayons</strong> to provide winter
                  clothing and back-to-school necessities for local children.
                </p>
                <p>
                  Launched in 2021, Harmony Biosciences began our{" "}
                  <Link to="/funding-programs/progress-at-the-heart">
                    Progress at the Heart
                  </Link>{" "}
                  program, which supports novel initiatives addressing
                  disparities and inequities in access to treatment and care in
                  the rare neurological disease community. In 2019, we launched
                  the{" "}
                  <Link to="/funding-programs/patients-at-the-heart">
                    Patients at the Heart
                  </Link>{" "}
                  program, which underscores our commitment to supporting
                  initiatives that help address the unique needs of individuals
                  with rare neurological disorders.
                </p>
                <img src={People} style={{ width: "100%" }} />
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default ourCulture
